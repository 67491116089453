import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

//components

import SideBar from '../../components/Sidebar';
import NavBar from '../../components/Navbar';

//pages

import DashBoard from '../Dashboard';
import OrderPage from '../OrderPage';
import OrderDetails from '../OrderDetails';
import OrderHistory from '../OrderHistory';
import Categories from '../Categories';
import AddProduct from '../AddProduct';
import Products from '../Products';
import { Carousel } from 'bootstrap';
import Slider from '../Slider';
import Register from '../Register';
import Transactions from '../Transactions';
import Reviews from '../Reviews';
import Customers from '../Customers';
import Login from '../Login';
import axios from 'axios';
import EditProduct from '../EditProduct';
import BrandLogo from '../../assets/images/brand_image.png';
import { Link } from 'react-router-dom';
import Enquiries from '../Enquiries';

const App = () => {
  axios.defaults.baseURL = 'https://backend.shikhartradelink.com';
  // axios.defaults.baseURL = 'http://localhost:5000';
  const location = useLocation();
  return (
    <>
      <section className='projectWrapper'>
        {location.pathname !== '/login' && (
          <aside className='sidebarWrapper'>
            <div className='sidebarWrapper-brand'>
              {/* <img src={BrandLogo} alt='' /> */}
              <h1>SHIKHAR TRADING</h1>
            </div>

            <hr />
            <SideBar />
          </aside>
        )}
        <section className='contentWrapper'>
          {location.pathname !== '/login' && <NavBar />}
          <Routes>
            <Route path='/' element={<DashBoard />} />
            <Route path='/login' element={<Login />} />
            <Route path='/order-list' element={<OrderPage />} />
            <Route path='/order-list/:pageNumber' element={<OrderPage />} />
            <Route path='/order-history' element={<OrderHistory />} />
            <Route
              path='/order-history/:pageNumber'
              element={<OrderHistory />}
            />
            <Route path='/categories' element={<Categories />} />
            <Route path='/add-products' element={<AddProduct />} />
            <Route path='/edit-product/:id' element={<EditProduct />} />
            <Route path='/products' element={<Products />} />
            <Route path='/products/:pageNumber' element={<Products />} />
            <Route path='/carousel' element={<Slider />} />
            <Route path='/register' element={<Register />} />
            <Route path='/register/:pageNumber' element={<Register />} />
            <Route path='/transactions' element={<Transactions />} />
            <Route path='/reviews/:id' element={<Reviews />} />
            <Route path='/customers' element={<Customers />} />
            <Route path='/enquiries' element={<Enquiries />} />
            <Route path='/enquiries/:pageNumber' element={<Enquiries />} />
            <Route path='/customers/:pageNumber' element={<Customers />} />
            <Route
              path='/order-list/order-details/:id'
              element={<OrderDetails />}
            />
          </Routes>
        </section>
      </section>
    </>
  );
};

export default App;
